.please-comment {
  .detail-please-comment {
    .title {
      color: #9b4951;
      text-align: center;
      font-weight: bold;
    }

    .content {
      img {
        width: 100%;
      }

      p {
        word-break: break-word !important;
        overflow-wrap: break-word !important;
      }

      span {
        word-break: break-word !important;
        overflow-wrap: break-word !important;
      }
    }
  }

  .hr {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #000000;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .submit-comments {
    .title {
      color: #188b81;
    }

    .btn-info {
      background-color: #11998e;
    }
  }

  .header-result {
    height: 2.5rem;
    background: linear-gradient(90deg, #11998e, #27e180);
    padding: 0.375rem;

    .title {
      padding-top: 0.2rem;

      a {
        text-decoration: none;
        color: #ffffff;
        font-size: 1em;
        font-weight: 500;
        padding-left: 1rem;

        &:hover {
          color: #ffffff;
        }
      }
    }
  }

  .content-result {
    margin-top: 1rem;

    .item {
      margin-bottom: 1rem;
      padding-right: 0.5rem;

      img {
        height: 6.25rem;
        object-fit: cover;
      }

      a {
        text-decoration: none;
        font-size: 1em;
        font-weight: 600;
        color: #555;
      }
    }
  }

  .form-please-comment {
    label {
      font-size: 0.938rem;
    }
  }
}
